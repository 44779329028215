<template>
    <estimate class="orders-item" mode="dealer" type="dealer" :headerData="headerData" :key="orderId" area="my-orders"/>
</template>

<script>
import estimate from '@/components/item-wizard/estimate.vue'
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
export default ({
    components: {
        estimate,
    },
    setup() {

      localStorage.setItem('estimateType', 'orders/dealer')

        const headerData = {
            backLink: {
                url: '/my-orders',
                title: 'Back to MY ORDERS'
            },
            title: 'ORDER',
            requestBtn: {
                title: 'request Order',
            },
            totalBtn: {
                title: 'ORDER TOTAL:'
            }
        }

        const route = useRoute()

        const orderId = computed(() => route.params.id )

        return {
            headerData,
            orderId,
        }
    },
})
</script>

<style  lang="scss">
.orders-item {
    &.estimate {
        padding: 0;
    }
    .estimate {
        &__header.sticky {
            top: 46px!important;
        }
    }
    .modal-wrapper {
        height: 100%;
        top: 0;
    }

}
</style>
